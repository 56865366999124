.cookie-banner {
  width: 100%;
  height: 3.7em;
  position: fixed;
  bottom: 1px;
  background: #d8dfe7;
  border-radius: 5px;
  z-index: 9;
  opacity: 0.85;
  transition: height, 0.5s linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cookie-banner.open {
  width: 100%;
  height: 3.7em;
  position: fixed;
  bottom: 1px;
  background: #d8dfe7;
  border-radius: 5px;
  z-index: 9;
  opacity: 0.85;
  -webkit-transition: height, 0.5s linear;
  -moz-transition: height, 0.5s linear;
  -ms-transition: height, 0.5s linear;
  -o-transition: height, 0.5s linear;
  transition: height, 0.5s linear;
}

#cookie-notice {
  color: #080d37;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: 2.3em;
}

#banner-close {
  color: #080d37;
  cursor: pointer;
  margin-left: 2em;
}
