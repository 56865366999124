.left-navigation {
    background: #ECF0F4;
    width: 220px;
    min-width: 220px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    position: fixed;
    z-index: 99;
    height: 100%;
}
.navigationMenu{
    padding: 0;
    margin: 0;
    min-height: calc(100vh - 136px);
}
.open-in-tab{
    width: 20px;
    height: 20px;
    background: red;
    display: inline-block;
    vertical-align: middle;
}
.analyticsSection{
    margin: 0 0 0 20px;
}
.left-navigation p{
    font-size: 1em;
}
.subCategoryList{
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #305A80;
}
.subCategoryList.arrow-right{
    background: url('../img/arrow-right.svg') no-repeat right center scroll;;
}
.subCategoryList .customLinkWrapper:after{
    display: none;
}
.categoryList{
    padding: 10px 15px 10px 10px;
    border-bottom: 1px solid #305A80;
}
.categoryListItemWrapper{
    display: none;
}
.categoryListItemWrapper ul:last-child{
    border-bottom: 0 none;
}
.subCategoryListItemWrapper{
    display: none;
    position: absolute;
    background: #ECF0F4;
    margin-top: -40px;
    padding: 15px;
    z-index: -1;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    max-height: calc(100vh - 20%);
    overflow: auto;
}
.subCategoryListItemWrapper li{
    font-size: 0.95em;
    border-bottom: 1px solid #305A80;
}
.subCategoryList:hover{
    background: rgba(48,90,128,0.3);
    color:#fff;
    cursor:pointer;
    border-radius: 3px;
}
.subCategoryList p{
    padding: 10px;
    margin: 0;
}
.subCategoryList p .customLinkWrapper{
    color:#090909;
}
.subCategoryList p:hover .customLinkWrapper{
    text-decoration: underline;
}
.subCategoryList.hovered .selectedCategory.active ~ .subCategoryListItemWrapper{
    margin-top: -80px;
}

.subCategoryList.hovered .subCategoryListItemWrapper{
    display: block;
    left: 200px;
    z-index: 999;
    width: 250px;
    color:#090909;
    font-weight: 400;
}
.subCategoryList.hovered .subCategoryListItem:active{
    background: rgba(48,90,128,1);
}
.subCategoryList.hovered .subCategoryListItem .customLinkWrapper:active{
    color:#ffffff;
    font-weight: 500;
}
.categoryList.show .categoryListItemWrapper{
    display: block;
    margin-top: 10px;
}
.subCategoryListItemWrapper li:last-child{
    border-bottom: 0 none;
}
.categoryListName{
    font-weight: 600;
    background: url('../img/arrrow-down.svg') no-repeat right center scroll;
    cursor:pointer;
    margin:0;
}
.categoryList.show .categoryListName{
    background: url('../img/arrow-up.svg') no-repeat right 6px scroll;
}
.subCategoryListItem .customLinkWrapper{
    color:#090909;
    display: block;
    padding: 10px;
}
.subCategoryListItem:hover{
    background: rgba(48,90,128,0.3);
    cursor:pointer;
    border-radius: 3px;
}
.subCategoryListItem:hover .customLinkWrapper{
    cursor:pointer;
    text-decoration: underline;
}
.subCategoryList.active{
    background-color: #305A80;
    border-radius: 3px;
}
.subCategoryList.active p > .customLinkWrapper{
    font-weight: 500;
}
.subCategoryList.active p.active > .customLinkWrapper{
    color: #ffffff;
}
.subCategoryList.active p.active{
    background: rgba(48,90,128,1);
    color: #ffffff;
    font-weight: 600;
    border-radius: 3px;
}
.topLevelCategory{
    font-size: 1.1em;
    font-weight: 600;
    padding: 10px 20px;
    margin-top: 20px;
}
.topLevelCategory.active{
    font-weight: 500;
    background: #305A80;
    border-radius: 3px;
    margin: 20px 10px;
}
.topLevelCategory.active .customLinkWrapper{
    color:#ffffff;
    display: flex;
}
.topLevelCategory .customLinkWrapper{
    color:#090909;
}

.topLevelCategory .customLinkWrapper:hover{
    color:#6DB0CC;
}
.topLevelCategory > .customLinkWrapper:after{
    display: none;
}
.subCategoryList .selectedCategory {
    display: none;
}
.subCategoryList .selectedCategory.active {
    display: block;
    color:#ffffff;
    font-weight: 500;
    background: #305A80;
    border-radius: 3px;
}
.subCategoryList.active .subCategoryListText{
    color: #090909;
    background: #ECF0F4;
}
.categoryList.show .categoryListName {
    padding: 0 0 10px 0;
}
.categoryList.show p.categoryListName::after {
    content: '';
    width: calc(100% - 30px);
    height: 1px;
    border-bottom: 1px solid #305A80;
    display: block;
    margin-top: 10px;
    position: absolute;
}