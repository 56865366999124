.writeup-heading{
    font-weight: 600;
    font-size: 1em;
    color: rgb(48, 90, 128);
    margin-top:1em;
}
.writeup-bullets{
    font-size: 1em; margin: 0.5em 0px;
    padding-left: 1em;
    position: relative;
    font-weight: 400;
    color: #39505E; 
}
.writeup-bullets:before{
    content:'-';
    display: block;
    position: absolute;
    top:0;
    left:0;
}