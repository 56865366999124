.lineChartHeading{
    font-size: 1em;
    font-weight:bold;
    text-align:left;
    padding: 10px 0;
    color: #305A80;
    display: block;
    margin: 0 auto;
    flex:0 1 48.5%;
}
.lineChartHeadingLabel{
    font-size: 1em;
    text-transform: uppercase;
}
.lineChartHeadingLabelBold{
    font-size: 1.1em;
    font-weight: bold;
}
.line-chart-container{
    flex:0 1 48.5%;
    border: 1px solid #305A80;
    border-radius: 2px;
    padding: 2em 0 0 0;
    position: relative;
}
.line-chart-container .recharts-wrapper{
    right: -1em;
}
.simple-charts-wrapper .recharts-wrapper{
    right: 0;
}