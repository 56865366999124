.cookie-policy h4 {
  text-align: center;
}

.cookie-policy p {
  text-align: justify;
  width: 80%;
  margin: 0 auto;
}

.pdf-frame {
  height: 83vh;
  width: 95%;
  margin: 0 auto;
}
.cookie-policy{
  width:90%;
  margin: 0 auto;
}
