.error-message {
    float: right;
    color: #546472;
    font-style: italic;
    width: 100%;
}

.error {
    margin-top: 10em;
    margin-left: 9em;
    width: 40em;
    height: 5em;
    border: 1px solid #9e9e9e12;
    box-shadow: 10px 8px 20px #88888840;
    padding: 0 1em 1em;
}

