
.how-to-offer{
    background: #d8dfe7;
    border-radius: 5px;
    padding: 2em;
    flex: 1 1 33%;
    min-height: 24em;
    margin: 0 1.5em;
}
.how-to-offer p{
    color:#305A80;
    font-weight: 500;
    margin: 0 0 0.8em 0;
}
.how-to-offer ul{
    padding:0;
    margin:0;
}
.how-to-offer li{
    font-size: 1.1em;
    line-height: 1.6em;
    position: relative;
    padding:0.3em 0em 0.3em 1.2em;
    color: #080d37;
}
.how-to-offer li:before{
    content: '';
    display: block;
    left:0;
    top: 0.9em;
    width: 0.5em;
    height: 0.5em;
    transform: rotate(45deg);
    position: absolute;
    background: #B70223;

}