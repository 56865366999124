.financialsContainer{
    margin: 132px 0 0 1em;
    position: relative;
}
.financial-view-wrapper{
    position: absolute;
    right: 12%;
}
.financial-fixed-header{
    position: fixed;
    top: 126px;
    width: calc(100% - 250px);
    background: #fff;
    left: 250px;
    padding: 1em 0 0;
    z-index: 1;
    box-shadow: 1px 1px 1px #ccc;
}
.financials-table{
    width: 95%;
    text-align: left;
    margin-top: 1em;
    border-collapse: collapse;
}
.financials-table th div{
    display: flex;
    align-items: baseline;;
    justify-content: center;
}
.table-heading td div{
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
}
.table-heading td {
    padding: 1em 0.5em;
}
.financials-table td div{
    display: flex;
    justify-content: space-evenly;
}
.financials-table td {
    padding: 0.5em 1em;
    color:#22415d;
    border-left: 1px solid #ABB7C0;
    border-right: 1px solid #ABB7C0;
}
.financials-table th {
    padding: 0.5em;
}
.financials-table thead tr{
    background: #ECF0F4;
    border-bottom: 1px solid #ABB7C0;
}
.financials-table tr > td> div > span{
    width: 50%;
    min-width: 100px;
    display: inline-block;
    text-align: right;
    padding: 0;
    white-space: nowrap;
}
.subRow-section.subRowBold td{
    padding: 0.7em 0.5em;
}
.subRow-section td:first-child{
    text-indent: 1em;
}
.subRow-section.subRowBold td:first-child{
    text-indent:1em;
}
.subRow-subSection td:first-child {
    text-indent: 2em;
}
.subRow-section td:first-child {
    text-indent: 1em;
}
.financials-table .table-heading td{
    padding:1em 0;
}
.cat-row{
    font-weight: bold;
    background: #D8DFE7;
    border-top: 1px solid #ABB7C0;
    border-bottom: 1px solid #ABB7C0;
}
.financial-table-heading{
    padding-left:1em;
    color:#090909;
    font-size:1.1em;
    font-weight:bold;
}

.subcat-row{
    font-weight: normal;
}
.subcat-row td:first-child{
    text-indent: 2em;
}
.subRow-total td{
    background: #ECF0F4;
    font-weight: 500;
    padding: 0.7em 0.5em;
}
.subRow-section.subRowBold{
    background: #ECF0F4;
    font-weight: 500;
}
.cat-row.detailed td{
    padding: 0.8em 0.5em;
    font-size: 1.05em;
    color:#080D37;
    text-indent: 1em;
}
.subRow-total{
    border-top: 1px solid #ABB7C0;
    border-bottom: 1px solid #ABB7C0;
}
.subRow-total td:first-child{
    text-indent: 1em;
}
.financials-table tr:last-child{
    border-bottom: 1px solid #ABB7C0;
}
.sort-elem.active.descending{
    background: url('../img/arrrow-down.svg') no-repeat right center;
}
.sort-elem.active.ascending{
    background: url('../img/arrow-up.svg') no-repeat right center;
}
.financials-table tr > td .sort-elem{
    cursor: pointer;
    margin-right: 1em;
    padding-right: 1em;
    background: url('../img/sorting-25.svg') no-repeat right center;
}
.sort-elem:hover{
    color:#080D37;
    font-weight: 700;
}
.financials-table .subcat-row:hover,.financials-table .subSubSection-subcategories:hover{
    background-color: rgba(109,176,204,0.5);
}
.financials-table .subRow-subSection:hover{
   background-color: #dfe4e8;
}
.financials-table .subRow-section:hover{
   background-color: #d4d8dc;
}

tr.subRow-section {
    background: #ECF0F4;
    font-weight: 500;
    border-top: 1px solid #D8DFE7;
    border-bottom: 1px solid #D8DFE7;
}
tr.subRow-section td{
    padding: 0.7em 0.5em;
}
tr.subRow-subSection {
    background: #f5f5f5;
    font-weight: 500;
    border-top: 1px solid #ECF0F4;
    border-bottom: 1px solid #ECF0F4;
}
tr.subRow-subSection td{
    padding: 0.6em 0.5em;
}
tr.subSubSection-subcategories td:first-child{
    padding-left: 3em;
    max-width: 250px;
}
.financials-table.SummaryView .subSubSection-subcategories{
    display: none;
}
.financials-table.SummaryView tr.subRow-subSection{
    background: #fff;
    border: 0 none;
    font-weight: normal;
}
.financials-table.SummaryView .cat-row{
    background: #fff;
    font-weight: normal;
}
.financials-table.SummaryView .cat-row td:first-child{
    font-weight: 500;
}
.financials-table.SummaryView .table-heading.intermediate-view{
    display: none;
}
.financials-table.IntermediateView .table-heading.compact-view{
    display: none;
}
.financials-table.FullDetailView .table-heading.compact-view{
    display: none;
}
.table-heading.compact-view .sort-elem{
    background: none;
    cursor: default;
}
.version2.financials-wrapper{
    position: fixed;
    width: calc(100% - 220px);
    background: #fff;
    z-index: 99;
}