.charts-info-icon{
    background: url('../img/info-circle.svg');
    height: 20px;
    background-size: 100%;
    width: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
}

.infoPopupWrapperStyle{
    background: #ECF0F4;
    width: 475px;
    font-size: 14px;
    padding: 1.5em 1.5em 1em 1.5em;
    border-radius: 5px;
    line-height: 1.5;
    box-shadow: 0 2px 4px 0 #58646B;
    border: 1px solid #6A7881;
    position: absolute;
    right: -10px;
    bottom: -20px;
    z-index: 99;
    color:#080d37;
}
.infoPopupWrapperStyle p{
    margin: 0;
    font-size: 1em;
    padding-bottom: 0.7em;;
}