.new-reports-fixed-section{
    position: fixed;
    top: 127px;
    left: 220px;
    width: calc(100% - 220px);
    padding: 2em 1em 1em 1em;
    z-index: 10;
    background: #fff;
}
.section-wise-report-tabs{
    display: flex;
    justify-content: left;
    margin: 2em 0 0 0;
    padding-left: 2em;
}
.toggle-image{
    cursor: pointer;
    transition: all 0.5s linear;
}
.section-wise-tabs-item{
    font-weight: bold;
    color: #090909;
    font-size: 1em;
    padding: 0.5em 1.5em;
    border-radius: 3px 3px 0 0;
    cursor: pointer;
    text-align: center;
    border: 1px solid #ABB7C0;
    min-width: 10em;
}
.section-wise-tabs-item.active{
    background: #305A80;
    border: 0 none;
    color: #fff;
}
.new-reports-container{
    margin-top: 204px;
    margin-left: 220px;
    width: calc(100vw - 235px);
}
.new-reports-container .lineChartHeading{
    margin-bottom: 1em;
    padding-left: 2em;
}

.section-toggler{
    border-top: 1px solid #ccc;
    padding: 1.5em 0 0 0;
    margin: 0 0.5em 0 1em;
    position: relative;
}
.section-toggler.collapse .toggle-component{
   display: none;
}
.toggle-icon-wrapper{
    position: absolute;
    right: 0;
    top: -9px;
    background: #fff;
}
.toggler-section-name{
    position: absolute;
    top: -10px;
    left: calc(50% - 150px);
    display: inline-block;
    width: 300px;
    text-align: center;
    background: #fff;
}
.section-toggler.expand:hover .commentModeControlsWrapper{
    background: rgba(0,0,0,0.4);
    position: absolute;
    top:0;
    width: 100%;
    display: block !important;
    z-index: 1;
}
.section-toggler.expand .toggler-section-name{
    display: none;
}
.section-toggler.collapse .toggler-section-name{
    display: block;
}
.commentModeControlsWrapper .comment-mode-select-wrapper{
    position: absolute;
    right: 1em;
    top: 1em;
    background: #fff;
    padding-left: 0;
    border-radius: 3px;
}
.comment-mode-select-label{
    padding:1em;
    display: inline-block;
}
.comment-mode-select-wrapper .dropdownWrapper.companySwitcher{
    padding: 0 1em 1em 1em;
}
.comment-mode-select-wrapper .dropdownWrapper.companySwitcher .dropdownList.show{
    width:100%;
}
.section-toggler.expand.commentMode.comment-top{
    padding-top: 0;
}
.section-toggler.expand.commentMode.comment-top textarea{
    margin: 5px 1.5em;
}
.section-toggler.expand.commentMode.comment-bottom textarea{
    margin: 5px 1em;
}
.section-toggler.expand.commentMode.comment-top .commentModeControlsWrapper{
    top: 61px;
}
.comment-label{
    padding: 0 1em 1em 1.5em;
    display: inline-block;
}
.lineChartHeading.report-full-section-heading span{
    display: block;
    text-align: center;
}
.report-style-wrapper{
    border: 1px solid #D8DFE7;
    margin: 1em;
    padding-top: 1em;
}
.report-heading{
    text-align: center;
    font-weight: 500;
}
.report-header{
    display: flex;
    justify-content: center;
    align-items: center;
}
.report-duration{
    margin-left: 1em;
}
.report-financial-table{
    width: 100%;
    border-collapse: collapse;
}
.report-financial-table tbody tr:hover{
background: rgba(109,176,204,0.5);
}
.report-financial-table-wrapper{
    padding: 0 2em;
}
.report-financial-table-left,.report-financial-table-right{
    border-collapse: collapse;
}
.report-financial-table-left,.report-financial-table-right thead tr{
    background: #D8DFE7;
    font-weight: bold;
}
.report-financial-table-left tr td {
    padding: 0.5em;
}
.report-financial-table-right tr td{
    padding: 0.5em 1em;
}
.report-financial-table-right tr th {
    padding: 0.5em;
}
.dropdownWrapper.profit-improvement-table-dropdown{
    padding-left: 0;
}
.dropdownWrapper.profit-improvement-table-dropdown .selectedValue{
    width: 50px;
    min-width: 50px;
}

.report-financial-table-right {
    overflow: hidden;
}

.report-financial-table-right tbody tr:hover {
    background-color: rgba(109,176,204,0.5);
}
.report-financial-table-right tbody tr td:hover{
    background-color: rgba(109,176,204,0.8);
}

.report-financial-table-right td{
    position: relative;
}
.report-financial-table-right td:hover::after {
    content: "";
    position: absolute;
    background-color: rgba(109,176,204,0.5);
    left: 0;
    top: -5000px;
    height: 10000px;
    width: 100%;
    z-index: -1;
}
.table-wrapper{
    text-align: center;
    position: relative;
}
.without-controls{
   margin: 1em 0;
}
.profit-improvement-scenario-writeup{

}
.profit-improvement-scenario-writeup ul{
    padding: 1em 1.5em;
}
.profit-improvement-scenario-writeup ul li{
   padding: 0.4em;
}
@media print{
    @page {
        size: portrait;
    }
}