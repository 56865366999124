.customLinkWrapper,.subCategoryLink{
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: space-between;
}
.popoutTooltip{
    top: -1.8em;
    right: -10px;
    font-weight: 400;
    font-size: 10px;
    color: #fff;
    white-space: nowrap;
    padding: 0.2em;
    border-radius: 3px;
    background: rgb(24 46 66);
    position: absolute;
}
.popoutIcon{
    width: 18px;
    height: 18px;
    position: absolute;
    cursor: pointer;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #c8e1f7;
    border-radius: 2px;
    right:20px;
}
.subCategoryLink{
    padding: 0.8em;
}
.customLink{
    flex:1 1 100%;
}
.customLink:hover{
    text-decoration: underline;
}