.writeup-heading{
    font-weight: bold;
    color:#39505E;
    font-size: 1em;
    margin-top: 1.5em;
}
.writeup-text{
    font-weight: 400;
    font-size: 0.9em;
    color:#090909;
    margin: 0.7em 0;
    display: flex;
}

.writeup-wrapper{
    background: #ECF0F4;
    flex: 1 1 50%;
    padding: 1em 2em;
    margin: 0 0.5em;
    border-radius: 3px;
}
.writeup-wrapper p {
    font-size: 1em;
    line-height: 1.5em;
}
.writeup-wrapper.heading{
    background: none;
    padding: 0 2em 1em 0;
    font-weight: bold;
    text-transform: uppercase;
}
.thumbsUp,.thumbsDown,.thumbsNeutral{
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5em;
}
.arrowBSUp,.arrowBSDown,.arrowBSNeutral{
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5em;
    margin-top: 2px;
}
.arrowPnlDownSales,.arrowPnlUpSales,.arrowPnlNeutralSales{
    width: 12px;
    height: 12px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5em;
    margin-top: 5px;
}
.arrowPnlUpSales{
    transform: rotate(90deg);
}
.arrowPnlNeutralSales{
    transform: rotate(90deg);
}
.arrowBSNeutral{
    transform: rotate(90deg);
}
.arrowBSUp{
    transform: rotate(0deg);
}
.arrowBSDown{
    transform: rotate(180deg);
}
.arrowPnlUpCos,.arrowPnlDownCos,.arrowPnlNeutralCos{
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5em;
    margin-top: 3px;
}
.arrowPnlUpCos{
    transform: rotate(180deg);
}
.arrowPnlNeutralCos {
    transform: rotate(90deg);
}
.arrowPnlDownCos{
    transform: rotate(-90deg);
}