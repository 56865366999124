.hero-container{
    margin-top: 6em;
}
.header{
    width: 100%;
    height: 60px;
    padding: 0 30px;
    border-bottom: 1px solid #abb7c0;
    position: fixed;
    background: #fff;
    top: 0;
    z-index: 100;
}
.services{
    margin-top: 3em;
}
.header a:after{
    display: none;
}
.get-started-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:3em 0;
}
.get-started-button-container span{
    text-transform: uppercase;
    width: 18em;
    font-size: 1.1em;
    display: block;
    text-align: center;
    padding: 1em;
}
.hero-text{
    text-align: center;
    font-size: 1.5em;
    color: #080d37;
}
.hero-text .highlight{
    font-weight: bold;
    font-style: italic;
}
.offer{
    background: #d8dfe7;
    border-radius: 5px;
    padding: 2em;
    flex: 1 1 33%;
    min-height: 21em;
    margin: 0 1.5em;
}
.offer p{
    color:#305A80;
    font-weight: 500;
    margin: 0 0 0.8em 0;
}
.offer li{
    font-size: 1.1em;
    line-height: 1.6em;
    position: relative;
    padding:0.3em 0em 0.3em 1.2em;
    color: #080d37;
}
.offer li:before{
    content: '';
    display: block;
    left:0;
    top: 0.9em;
    width: 0.5em;
    height: 0.5em;
    transform: rotate(45deg);
    position: absolute;
    background: #B70223;

}
.thank-you-text-section{
    margin-top:3em;
}
.thank-you-text-section p{
    font-size: 1.1em;
    padding: 0;
    text-align: center;
    margin: 0.5em;
}
.offer ul{
    padding:0;
    margin:0;
}
.offer-section{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: flex-start;
    width: 95%;
    padding-top: 2em;
    margin: 0 auto;
}
.input-button {
    padding: 0.8em;
    vertical-align: middle;
    margin-right: 1em;
    font-size: 1em;
    min-width: 230px;
}