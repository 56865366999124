.pieChart {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
}
.pieChart >div{
    flex:33% 0 0;
}
.pieChartHeading{
    font-size: 0.9em;
    font-weight:bold;
    text-align:center;
    padding: 10px 0;
}
.pieChartContainer{
    padding-top: 30px;
}
.pieChartNoData{
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin: 10% 0;
}
.pieChartHeadingLabel{
    color:#305A80;
    width: 90%;
    display: block;
    text-align: center;
    margin: 0 auto;
}
.pieChartHeadingLabelAmount{
    padding: 0 0.5em;
    font-size: 1.2em;
    color: #090909;
}
.pieChartHeadingLabelAmount span p{
    font-size: 0.8em;
    margin-bottom: 0;
    font-weight: 500;
}
