.dropdownList.hide{
    display: none;
}
.dropdownList.show{
    margin: 0;
    padding: 0;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    position: absolute;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    max-width: 230px;
    background: #ffffff;
    z-index: 99;
}
.dropdownListItem{
    padding: 8px 20px;
    flex: 0 0 33.3%;
    cursor: pointer;
    border:1px solid #D8DFE7;

}

.dropdownListItem.notSelectable{
    padding: 8px 20px;
    flex: 0 0 33.3%;
    border:1px solid #D8DFE7;
    color: dimgrey;
}

.dropdownListItem.notSelectable:hover{
    background: #ffffff;
    cursor: default;
}

.dropdownListItem:hover{
    background: rgba(48,90,128,0.5);
}
.selectedValue{
    border-bottom: 1px solid #305A80;
    margin: 10px;
    padding: 8px 20px 5px 5px;
    cursor: pointer;
}
.dropdownListItem.selected{
    background: rgba(48,90,128,1);
    color:#fff;
}
.selectedValue.upArrow{
    background: url('../img/dropdownArrowUp.svg') no-repeat right center;
}
.selectedValue.downArrow{
    background: url('../img/dropdownArrowDown.svg') no-repeat right center;
}
