.anomalies-wrapper{
    flex: 0 1 50%;
}
.anomalies-wrapper li{
    font-size: 1em;
    padding: 0.2em 0;
    font-weight: 400;
    color: #080D37;
}
.anomalies-wrapper ul{
    padding: 0 0.5em;
    margin: 0;
}
.anomalies-top-wrapper .writeups-container .writeup-wrapper.heading{
    font-size: 1.1em;
    color: #305A80;
    text-transform: none;
    padding-bottom: 0;
    margin-left: 0.8em;
    font-weight: 500;
}
.orange{
    color:#FA6400;
}
.anomalies-top-wrapper{
    margin: 1em;
    border-radius: 3px;
}
.anomalies-top-wrapper .writeups-container.heading{
    margin-top: 1.5em;
    margin-bottom: 0.5em;
}
.anomalies-top-wrapper .anomalies-wrapper{
    padding: 1em;
    background: #ECF0F4;
    margin-right: 1.5em;
    margin-left: 1em;
    border-radius: 3px;
    /*border: 1px solid #cbcfde;*/
}
.not-found-text{
    color:#080D37;
}