.upload-data {
  width: 35em;
  height: 120px;
  margin: 2em auto;
  display: flex;
}

.upload-sources {
  margin: 0 auto;
  height: 120px;
  width: 120px;
  border: 1px solid #305a80;
  border-radius: 3px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-sources.disabled {
  cursor: default;
}
.upload-sources.disabled:hover {
  border: 1px solid #305a80;
  box-shadow:none;
}
.upload-sources:hover{
  border: 2px solid #6DB0CC;
  box-shadow:0 2px 4px 0 rgba(0,0,0,0.5);
}
.upload-sources:active{
  border: 2px solid #6DB0CC;
  box-shadow:0 2px 4px 0 rgba(0,0,0,0.5);
  background-color: rgba(48,90,128,0.2);
}

.upload-sources img {
  width: 75px;
  height: 75px;
}

.hidden {
  visibility: hidden;
  display: none;
}
