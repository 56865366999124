.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-Wrapper {
  margin-top: 60px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: stretch;
  min-height: calc(100vh - 116px);
}
.content-wrapper{
  flex-grow: 1;
  margin-left: 220px;
}
.reportsContainer{
  padding:1.5em 1em;
  margin-top: 80px;
}
.analysisContainer{
  padding:1.5em 1em;
  margin-top: 105px;
}

.App-link {
  color: #61dafb;
}

.Toastify__toast {
  min-height: 45px;
}
.separator-line{
  margin: 1em 0 1em 0;
  border: 0 none;
  border-bottom: 1px solid #ABB7C0;
}
.line-charts-wrapper{
  display:flex;
  flex-wrap:nowrap;
  justify-content:space-around;
  padding-top:1em;
}
.writeups-container{
  line-height: 1.5em;
  display: flex;
}
.writeups-container .green{
color:#2ca02c;
  font-weight: 500;
}
.writeups-container .red{
color: #B70223;
  font-weight: 500;
}

.bs-representation-title {
  font-size: 1em;
  font-weight: bold;
  color: #305a80;
  text-align: center;
  width: 50%;
}
.page-title{
  margin: 0 auto;
  text-align: center;
  position: fixed;
  width: calc(100% - 220px);
  top: 117px;
  background: #ffffff;
  padding: 1em;
  z-index: 1;
  box-shadow: 0px 0px 5px #ccc;
}
.content {
  height: 100%;
  min-height: 500px;
}
.chart-error{
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  padding-left: 40px;
  width: 300px;
  margin: 30px auto;
  display: flex;
  align-items: center;
}
.error-icon{
  background: url('./img/error-icon.svg') no-repeat left center;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media print {

  .left-navigation, .header, #survicate-fb-box, .widget-app-container, #hubspot-messages-iframe-container {
    display: none!important;
  }
  .content-wrapper, .subHeader {
    display: block;
    position: relative!important;
    margin-left: 0!important;
    width: 100% !important;
    left: 0!important;
    top: 0;
  }
#notSupportingOverlay, #overlay-text{
  display: none !important;
}


  .page-title {
    top: 0;
    position: relative;
    left: 0;
    padding-left: 0;
    width: 100%;
  }


  .left-navigation, .header, #survicate-fb-box, .widget-app-container, .hubspot.messages-body {
    display: none;
  }

  .content-wrapper, .subHeader {
    display: block;
    position: relative;
    margin-left: 0;
    width: 100%;
    left: 0;
    top: 0;
  }

  .subHeader {
    display: flex;
  }

  .page-title {
    top: 0;
    position: relative;
    left: 0;
    padding-left: 0;
    width: 100%;
  }

  .line-charts-wrapper {
    page-break-after: avoid;
    page-break-inside: avoid;
  }

  .App-Wrapper {
    margin-top: 0;
    display: block;
  }

  .print-page-break-before {
    page-break-before: always;
  }

  .print-page-break-after {
    page-break-after: always;
  }

  .doubleYAxis-charts-wrapper .recharts-wrapper {
    width: 100% !important;
  }

  .line-chart-container > div{
    flex: 0 1 13%;
  }

  .line-chart-container > div, .doubleYAxis-charts-container div {
    max-width: 100% !important;
  }

  .doubleYAxis-charts-container div {
    max-width: 100% !important;
    width: 100% !important;
  }

  .doubleYAxis-charts-container > div {
    flex: 0 0 12%;
  }

  ul, .doubleYAxis-charts-wrapper{
    justify-content: start!important;
  }
  #hubspot-messages-iframe-container iframe {
    visibility: hidden!important;
    display: none!important;
    opacity: 0!important;
  }
  .doubleYAxis-charts-wrapper .recharts-surface{
    width: 185px!important;
  }
  .line-chart-container .recharts-surface{
    width: 460px!important;
  }

  .recharts-legend-wrapper .recharts-legend-item{
    display: inline!important;
    position: relative;
  }
  .recharts-legend-wrapper .recharts-legend-item .recharts-surface{
    width: 10px!important;
  }
  .line-chart-container .recharts-wrapper {
    right: -5em!important;
  }
  .separator-line{
    border-bottom: none;
  }
  .new-reports-container{
    width: 100% !important;
    margin: 0 auto !important;
  }
  .version1{
    display: none;
  }
  .new-reports-fixed-section{
    display: none;
  }
  .report-style-wrapper{
    margin: 0;
  }
  .recharts-layer.recharts-brush{
    display: none !important;
  }
}
/*#survicate-fb-box .survicate_button_3-nE4{
  width: 25px!important;
}*/

.dropdownListItem.notSelectable:hover:after{
  position: absolute;
  display: block;
  color: red;
  content: "Unable to select as date is in the future. Please change month or year.";
  text-align: center;
  padding: 10px;
  font-weight: 400;
  font-size: 1em;
  color: #fff;
  white-space: nowrap;
  border-radius: 3px;
  z-index: 999;
  background: rgb(24 46 66);
}
