.double-axis-chart-legend{
  display: flex;
}
.double-axis-chart-legend li{
  padding: 0 0 0 2em;
}
.custom-tooltip{
  background: rgba(255,255,255,0.9);
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 0 1em 1em 1em;
}
.tooltip-heading{
  font-size: 1.1em;
  font-weight: 500;
}
.tooltip-label{
  line-height: 1.5em;
}
.tooltip-value{
  line-height: 2em;
  font-size: 1.1em;
  font-weight: 500;
}
.legend-square{
  height: 10px;
  width: 10px;
  display: inline-block;
  margin-right: 0.5em;
  vertical-align: middle;
}
.legend-line{
  width: 10px;
  height: 2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
}
.custom-legend{
  display: flex;
  justify-content: center;
}