
.subHeader{
    display: flex;
    padding: 1.5em 0 1em 0;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ECF0F4;
    position: fixed;
    width: calc(100% - 220px);
    left: 220px;
    top:60px;
    background: #ECF0F4;
    z-index: 99;
    box-shadow: 0 0 3px rgba(0,0,0,0.5);
}
.header-center-section{
    display: flex;
    justify-content: center;
    flex: 1 1 60%;
}
.subHeader_DateLabel{
    margin-left: 30px;
}
.subheader-date-picker{
    display: flex;
}
.companySwitcher{
    padding-left: 2em;
    flex: 1 1 20%;
}
.companySwitcher .selectedValue{
    display: inline-block;
    min-width: 235px;
    margin: 0;
    padding: 0 10px 5px 5px;
}
.companySwitcher li.dropdownListItem {
    flex: 0 0 100%;
}
.subHeaderHeadingText {
    text-align: center;
    padding: 0;
    margin: 0 1em 0 -1em;
    font-size: 1.2em;
}
.ytd-month{
    margin-left: 5%;
    font-size: 1em;
}
.ytd-month span{
   color:#305A80;
}
.version2 .subHeader{
    left:0;
    width: 100%;
    position: static;
    background: #ffffff;
}
.version2.financials-wrapper .subHeader{
    box-shadow: none;
}
.financials-wrapper .version2Heading{
    margin-right: 17em;
}

.version2 .header-center-section{
    display: none;
}
.version2 .header-center-section{
    display: none;
}
.version2Heading{
    text-align: center;
    flex: 1 1 100%;
    margin: 0 10em 0 0;
    font-weight: bold;
    font-size: 1.2em;
}
.settings-icon{
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 3px;
    margin-right: 20px;
    cursor: pointer;
}
.subHeader .settings-menu.show{
    right: 1em;
}
.subHeader .settings-menu.show a{
    right: 1em;
    text-decoration: none;
}