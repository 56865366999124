.sectionHeading{
    font-size: 1em;
    font-weight: bold;
    padding: 0.5em;
    border-bottom: 1px solid #abb7c0;
    margin: 0.5em 0;
    color: #090909;
    position: sticky;
    top: 0;
    background: rgb(236, 240, 244);
}
.rightCategories{
    border-top: 1px solid rgb(48, 90, 128);
    border-left: 1px solid rgb(48, 90, 128);
    box-shadow: -1px 2px 2px 1px rgb(48 90 128);
    height: fit-content;
    z-index: 1;
    margin-left: 1em;
}
.rightCategories .sectionHeading{
    border-bottom: 0 none;
    margin: 0;
    padding: 0.5em;
}
.draggable-item{
    min-width: 15em;
    max-width: 15em;
    margin: 1em 0 0 10px;
    border-radius:3px;
    text-align: center;
}
.action-buttons{
    position: absolute;
    right: 5em;
    top: 11.5em;
}
.reset-button{
    color: #305A80;
    display: inline-block;
    outline:none;
    border: 0 none;
    margin: 0 1em;
    font-size: 1.1em;
    background: transparent;
    cursor: pointer;
}
.reset-button:after{
    content: '';
    display: block;
    border-bottom: 2px solid #305A80;
}
.chartsOfAccountsTabs{

}
.pnl-drag-drop .rightCategories .sectionHeading{
    padding: 0.1em 0;
}
.tab-button{
    font-weight: bold;
    color: #090909;
    font-size: 1em;
    padding: 1em 1.5em;
    border-radius: 3px 3px 0 0;
    cursor: pointer;
    text-align: center;
    border: 1px solid #ABB7C0;
    min-width: 10em;
}
.tab-button:hover{
    background: #ABB7C0;
}
.tab-button.active {
    background: #305A80;
    border: 0 none;
    color:#fff;
}
.settings-menu.show a {
    font-weight: bold;
    text-decoration: underline;
}
.primary-btn.disabled{
    background: #aba7a7;
    cursor: default;
}
.rightCategoriesHeading{
    font-weight: bold;
    font-size: 1.1em;
    padding: 1em;
    color: #305A80;
    text-align: center;
    position: sticky;
    top: 0;
    background: #fff;
    border: 1px solid #fff;
    margin: 0;
    z-index: 10;
}
.bs-drag-drop .draggable-item{
    min-width: 15em;
    max-width: 15em;
    margin:1em 0.5em 0 0.5em;
}
.bs-drag-drop .rightCategories .draggable-item{
    margin:0.5em 0.5em 0 0.5em;
}

.draggable-item-bs{
    background:rgb(236, 240, 244);
    margin: 0.5em 0.5em;
    padding-bottom: 0.5em;
    border-radius: 5px;
    width: fit-content;
}
.rightCategories .draggable-item-bs{
    margin: 0.1em 0.5em;
}
.rightCategories .draggable-item-bs .draggable-item .sectionHeading{
   padding: 0;
}
.draggable-item-bs .draggable-item .sectionHeading{
    padding-top: 0;
    color: #384751;
}
.draggable-item-bs .sectionHeading{
    text-align: center;
    padding-top: 0.5em;
}
.sectionHeadingLevel2{
    font-size: 1em;
    font-weight: bold;
    padding: 0.5em;
    border-bottom: 1px solid #abb7c0;
    margin: 0.5em 0;
    color: #39505E;

}
.sectionHeadingRightNav{
    font-size: 1em;
    font-weight: bold;
    padding: 0.5em;
    border-bottom: 1px solid #abb7c0;
    margin: 0.5em 0;
    color: #39505E;
    text-align: center;
}
.sectionHeadingRightNavLevel2{
    font-size: 1em;
    font-weight: bold;
    padding: 0.2em;
    margin: 0;
    color: #39505E;
    text-align: center;
}
@media screen and (max-width: 1459px){
    .action-buttons{
        top: 12em;
    }
}
