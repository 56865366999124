
.company-data:last-child{
    border-bottom: 0 none;
}

#download-excel {
    margin-left: 1em;
    cursor: pointer;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #305A80;
    border-radius: 3px;
}
#download-excel:hover{
    background: #6DB0CC;
}
#download-excel img {
    width: 35px;
}
.generate-report:hover,
.download-excel:hover {
    background-color: #6DB0CC;
    color: #fff;
}

.report-start-date,
.report-end-date {
    width: 17%;
    display: inline-block;
}

.report-start-date span,
.report-end-date span {
    font-size: initial;
    margin-right: 1em;
}

#report-frame {
    width: 100%;
}

.report-wrapper {
    height: 100vh;
    width: 100%;
    margin-top: 2em;
}
.reports-list{
    margin: 20px 0;
    padding: 0;
    display: flex;
    justify-content: center;
}
.report-data{
    display: flex;
}
.report-data .company-name{
    width: 20%;
    font-size: 16px;
    line-height: 27px;
}
.report-sections{
    text-align: center;
    font-weight: 500;
    margin-top: 3em;
}
.report-section p{
    margin:0;
}
.report-sections li {
    display: inline-block;
    margin-right: 2em;
    color: #305A80;
    font-size: 1.1em;
}

.loading-msg p{
    text-align: center;
    font-size: 1em;
    line-height: 2em;
}
.report-offer li{
    position: relative;
    padding: 0.5em 0em 0.3em 1.2em;
}
.report-offer li:before{
    content: '';
    display: block;
    left:0;
    top: 0.9em;
    width: 0.5em;
    height: 0.5em;
    transform: rotate(45deg);
    position: absolute;
    background: #B70223;
}
.random-quote{
    font-weight: 500;
    font-size: 1.1em;
    max-width: 60%;
    margin: 0 auto;
    padding: 2em;
    line-height: 1.5;
    font-style: italic;
    background: #475962;
    border-radius: 5px;
    color: #fff;
}
.random-quote p{
    margin: 0;
}
.random-quote span{
    display: block;
    text-align: right;
}