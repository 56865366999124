.overall-contribution-container{
    flex:0 0 33%;
}
.overall-contribution{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-top: 2em;
}
.overall-contribution-headings{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.overall-contribution-headings .lineChartHeading{
    flex: 0 0 33%;
    text-align: center;
    margin-bottom: 0;
    margin-top: 2em;
}
.container-for-chart-info-icon{
    position: relative;
    padding: 20px 0 0;
}
.container-for-chart-info-icon .charts-info-icon{
    top:0;
}
