.key-insights-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.key-insight{
    flex: 0 1 22%;
    background: #ECF0F4;
    padding: 1.5em;
    border-radius: 3px;
    margin-bottom: 1em;
    font-weight: 600;
    text-align: center;
}
.key-insight label{
    color:#39505E;
}
.key-insight > span{
    padding-left: 1em;
}
.arrow-positive-negative{
    height: 0.8em;
    vertical-align: middle;
    margin-right: 0.5em;
}
.thumbs-positive-negative{
    height: 1em;
    vertical-align: middle;
    margin-right: 0.5em;
}
.doubleYAxis-charts-wrapper{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
}
.doubleYAxis-charts-container{
    flex: 0 0 20%;
}
.doubleYAxis-charts-container .chart-error{
    max-width: 200px;
}
.cost-saving-cat-title{
    flex: 0 0 20%;
    max-width: 20%;
    font-weight: 500;
    color: #305A80;
}
.arrow-positive-up{
    transform: rotate(90deg);
    height: 0.8em;
    vertical-align: middle;
    margin-right: 0.5em;
}
.arrow-negative-down{
    height: 0.8em;
    vertical-align: middle;
    margin-right: 0.5em;
}